import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AppAuthService} from '../../services/app/app-auth/app-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthActivateGuard implements CanActivate {
  constructor(private router: Router,
              private appAuthService: AppAuthService) {
  }

  /**
   * The function allows to define if the user has access to a url.
   * @param route Router
   */
  canActivate(route: ActivatedRouteSnapshot) {
    const {roles, rearward} = route.data;
    if (roles && roles.length) {
      const validator = this.appAuthService.hasRoles(roles);
      return validator ? true : this.router.parseUrl(`/login`);
    } else if (!rearward) {
      return this.appAuthService.userConnected();
    }
  }
}
