import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, flatMap, map, tap} from 'rxjs/operators';
import {User} from '../../../models/user.model';
import {OnExecuteData} from 'ng-recaptcha';


export interface AuthResponseData {
  id: string;
  email: string;
  roles: string[];
}

export interface JWT {
  token: string;
  refresh_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppAuthService implements OnDestroy {
  private user = new BehaviorSubject<User>(null);

  constructor(private httpClient: HttpClient,
              private router: Router) {
  }

  get userIsAuthenticated(): Observable<boolean> {
    return this.user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user;
        } else {
          return false;
        }
      })
    );
  }

  get userId() {
    return this.user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      })
    );
  }

  ngOnDestroy() {
  }

  /**
   * The function is used to obtain a session authentication token.
   */
  login(captcha: OnExecuteData, email: string, password: string): Observable<AuthResponseData> {
    return this.loginSession(captcha, email, password).pipe(finalize(() => {
    }), flatMap(() => this.getCredentials()));
  }

  /**
   * The function sends an authentication request to the server.
   */
  loginSession(captcha: OnExecuteData, email: string, password: string, remember: boolean = true): Observable<any> {
    return this.httpClient
      .post<JWT>('/api/login_check', {
        username: email,
        password
      }, { params: { captchaToken: captcha.token }}).pipe(map(({token, refresh_token}) => {
        localStorage.setItem('KEY_JWT_TOKEN', token);
        localStorage.setItem('KEY_JWT_TOKEN_REFRESH', refresh_token);
      }));
  }

  reconnection() {
    return this.reconnectionSession().pipe(finalize(() => {
    }), flatMap(() => this.getCredentials()));
  }

  /**
   * The function sends the jwt recovery token.
   */
  reconnectionSession(): Observable<any> {
    const tokenRecovery = localStorage.getItem('KEY_JWT_TOKEN_REFRESH');
    return this.httpClient.post<JWT>(`/api/token/refresh`, {},
      {
        params: {refresh_token: tokenRecovery},
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).pipe(map(({token, refresh_token}) => {
      localStorage.setItem('KEY_JWT_TOKEN', token);
      localStorage.setItem('KEY_JWT_TOKEN_REFRESH', refresh_token);
    }));
  }

  /**
   * The function allows to reconnect the user.
   */
  async autoLogin(url: string) {
    let authObs: Observable<AuthResponseData>;
    authObs = await this.reconnection();
    authObs.subscribe(() => {
      this.router.navigateByUrl(url).catch();
    }, err => {
      this.router.navigateByUrl(`/login`).catch();
      console.error(err);
    });
    return this.userConnected();
  }

  /**
   * The function allows to get user account.
   */
  getCredentials(): Observable<AuthResponseData> {
    return this.httpClient.get<AuthResponseData>('/api/account').pipe(tap(this.setUserData.bind(this)));
  }

  userConnected(): boolean {
    return !!(this.user && this.user.value);
  }

  userData(): User {
    return (this.user && this.user.value);
  }

  /**
   * The function allows to check if the current user contains a role.
   */
  hasRoles(roles: string[] = []): boolean {
    let selector = false;
    for (const role of roles) {
      if (this.user.value && this.user.value.roles.includes(role)) {
        selector = true;
        break;
      }
    }
    return selector;
  }

  async logout() {
    await localStorage.removeItem('KEY_JWT_TOKEN');
    await localStorage.removeItem('KEY_JWT_TOKEN_REFRESH');
    await this.router.navigateByUrl(`/login`);
  }

  /**
   * The function allows to configure the current user after logging in.
   */
  private setUserData(userData: AuthResponseData) {
    const userCurrent = new User(
      userData.id,
      userData.email,
      userData.roles,
    );
    this.user.next(userCurrent);
  }
}
