import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthActivateGuard} from './guards/auth-activate/auth-activate.guard';
import {AuthLoadGuard} from './guards/auth-load/auth-load.guard';


const routes: Routes = [
  {
    path: '', redirectTo: '/', pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/admin/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'legals',
    loadChildren: () => import('./pages/legals/legals.module').then(m => m.LegalsModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'admin/main',
    loadChildren: () => import('./pages/admin/main/main.module').then(m => m.MainModule),
    canLoad: [AuthLoadGuard],
    canActivate: [AuthActivateGuard],
    data: {
      rearward: false,
    }
  },
  {
    path: '**', redirectTo: '/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
