import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

  constructor(@Inject('BASE_API_URL') private baseUrl: string | object) {
  }

  /**
   * the function allows to change the current http to call API.
   */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('infos.votrexpert.com')) {
      return next.handle(request)
    }

    return next.handle(request.clone({url: this.baseUrl + request.url}));
  }
}
