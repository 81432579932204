import {Moment} from 'moment';
import * as moment from 'moment';

export class User {
  constructor(
    public id: string,
    public email: string,
    public roles: string[],
  ) {
  }
}
