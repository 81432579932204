import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AppAuthService} from '../../services/app/app-auth/app-auth.service';
import {switchMap, take, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthLoadGuard implements CanLoad {

  constructor(private appAuthService: AppAuthService,
              private router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<any>{
    return this.appAuthService.userIsAuthenticated.pipe(
      take(1),
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          const url = this.getCurrentPath(segments);
          return this.appAuthService.autoLogin(url);
        } else {
          return of(isAuthenticated);
        }
      }),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.parseUrl('/login');
        }
      })
    );
  }

  getCurrentPath(segments: UrlSegment[]): string {
    let url = ``;
    for (const segment of segments) {
      url += `/` + segment.path;
    }
    return url;
  }
}
