import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {BaseUrlInterceptor} from './interceptors/http-url.interceptor';
import {TokenInterceptor} from './interceptors/http-header.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service} from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgxSpinnerModule,
        ToastrModule.forRoot(),
    ],
  providers: [
    {provide: `BASE_API_URL`, useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: ToastrService, useClass: ToastrService},
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lf89rQaAAAAAIn4eewZ-NH9jneR6kfu34oICpcD' },
    ReCaptchaV3Service
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
